import request from '@/utils/request'

// 查询待办任务列表
export function todoList(query) {
  return request({
    url: '/flowable/task/todoList',
    method: 'get',
    params: query
  })
}

// 完成任务
export function complete(data) {
  return request({
    url: '/flowable/task/complete',
    method: 'post',
    data: data
  })
}

// 委派任务
export function delegate(data) {
  return request({
    url: '/flowable/task/delegate',
    method: 'post',
    data: data
  })
}

// 退回任务
export function returnTask(data) {
  return request({
    url: '/flowable/task/return',
    method: 'post',
    data: data
  })
}

// 驳回任务
export function rejectTask(data) {
  return request({
    url: '/flowable/task/reject',
    method: 'post',
    data: data
  })
}

// 可退回任务列表
export function returnList(data) {
  return request({
    url: '/flowable/task/returnList',
    method: 'post',
    data: data
  })
}

// 下一节点
export function getNextFlowNode(data) {
  return request({
    url: '/flowable/task/nextFlowNode',
    method: 'post',
    data: data
  })
}

// 下一节点
export function getNextFlowNodeByStart(data) {
  return request({
    url: '/flowable/task/nextFlowNodeByStart',
    method: 'post',
    data: data
  })
}

// 部署流程实例
export function deployStart(deployId) {
  return request({
    url: '/flowable/process/startFlow/' + deployId,
    method: 'get',
  })
}

// 查询流程定义详细
export function getDeployment(id) {
  return request({
    url: '/system/deployment/' + id,
    method: 'get'
  })
}

// 新增流程定义
export function addDeployment(data) {
  return request({
    url: '/system/deployment',
    method: 'post',
    data: data
  })
}

// 修改流程定义
export function updateDeployment(data) {
  return request({
    url: '/system/deployment',
    method: 'put',
    data: data
  })
}

// 删除流程定义
export function delDeployment(id) {
  return request({
    url: '/system/deployment/' + id,
    method: 'delete'
  })
}

// 导出流程定义
export function exportDeployment(query) {
  return request({
    url: '/system/deployment/export',
    method: 'get',
    params: query
  })
}
// 流程节点表单
export function flowTaskForm(query) {
  return request({
    url: '/flowable/task/flowTaskForm',
    method: 'get',
    params: query
  })
}

// 根据项目编码查询项目数据
export function queryProject(data) {
  return request({
    url: '/bpm/bpmProject/queryProject',
    method: 'post',
    data: data
  })
}

// 项目提交
export function summitProject(data) {
  return request({
    url: '/bpm/bpmRecordProject/summitProject',
    method: 'post',
    data: data
  })
}

// 项目保存
export function bpmRecordProject(data) {
  return request({
    url: '/bpm/bpmRecordProject',
    method: 'post',
    data: data
  })
}

// 项目更新
export function bpmRecordProjectUpdate(data) {
  return request({
    url: '/bpm/bpmRecordProject',
    method: 'put',
    data: data
  })
}

// 项目锁定
export function lockProject(data) {
  return request({
    url: '/bpm/bpmRecordProject/lockProject',
    method: 'post',
    data: data
  })
}

// 项目解锁
export function unLockProject(data) {
  return request({
    url: '/bpm/bpmRecordProject/unLockProject',
    method: 'post',
    data: data
  })
}

// 任务提交
export function summitTask(data) {
  return request({
    url: '/bpm/bpmRecordProject/summitTask',
    method: 'post',
    data: data
  })
}

// 任务驳回
export function rejectTasks(data) {
  return request({
    url: '/bpm/bpmRecordProject/rejectTask',
    method: 'post',
    data: data
  })
}

// 项目作废
export function cancelProject(data) {
  return request({
    url: '/bpm/bpmRecordProject/cancelProject',
    method: 'post',
    data: data
  })
}

// 项目异常办结
export function overProject(data) {
  return request({
    url: '/bpm/bpmRecordProject/overProject',
    method: 'post',
    data: data
  })
}

// 查询项目明细
export function bpmRecordProjectDetail(id) {
  return request({
    url: `/bpm/bpmRecordProject/get/${id}`,
    method: 'get',
  })
}

// 查询项目明细
export function getDraft(params) {
  return request({
    url: `/bpm/bpmRecordProject/getDraft`,
    method: 'get',
    params:params
  })
}

// 移动端查询项目草稿明细
export function recGetDraft(params) {
  return request({
    url: `/bpm/bpmCloud/api/recordProject/getDraft`,
    method: 'get',
    params:params
  })
}

// 移动端查询项目明细
export function recGetDrafts(id) {
  return request({
    url: `/bpm/bpmCloud/api/recordProject/get/${id}`,
    method: 'get'
  })
}

// 移动端项目发起新增
export function recordProject(data) {
  return request({
    url: `/bpm/bpmCloud/api/recordProject`,
    method: 'post',
    data: data
  })
}

// 移动端项目发起更新
export function recordProjectUpdate(data) {
  return request({
    url: '/bpm/bpmCloud/api/recordProject',
    method: 'put',
    data: data
  })
}

// 移动端项目发起提交
export function recSummitProject(data) {
  return request({
    url: `/bpm/bpmCloud/api/recordProject/summitProject`,
    method: 'post',
    data: data
  })
}

// 任务传阅
export function shareTask(data) {
  return request({
    url: '/bpm/bpmRecordProject/shareTask',
    method: 'post',
    data: data
  })
}

// 任务转办
export function assignTask(data) {
  return request({
    url: '/bpm/bpmRecordProject/assignTask',
    method: 'post',
    data: data
  })
}

// 创建子任务
export function createTask(data) {
  return request({
    url: '/bpm/bpmRecordProject/createTask',
    method: 'post',
    data: data
  })
}

// 查询下节点任务办理人员
export function nextTaskPersonnel(data) {
  return request({
    url: '/bpm/bpmRecordProject/nextTaskPersonnel',
    method: 'get',
    params: data
  })
}

// 提交任务指定下节点任务办理人员
export function submitNextTasks(data) {
  return request({
    url: '/bpm/bpmRecordProject/submitNextTasks',
    method: 'post',
    data: data
  })
}
// 提交任务指定下节点任务办理人员
export function updateShareTasksStatus(data) {
  return request({
    url: '/bpm/bpmRecordProject/updateShareTasksStatus',
    method: 'post',
    params: data
  })
}

// 不分页查询所有修改记录数据
export function recordLog(data) {
  return request({
    url: '/system/recordLog/list',
    method: 'get',
    params: data
  })
}

// 批量新增修改记录数据
export function recordLogBatch(data) {
  return request({
    url: '/system/recordLog/batch',
    method: 'post',
    data: data
  })
}

// 批量新增驳回修改记录数据
export function rejectedLog(data) {
  return request({
    url: '/bpm/bpmLog/rejectedLog',
    method: 'post',
    data: data
  })
}

// 新增异常操作记录
export function comabnormalLog(data) {
  return request({
    url: '/bpm/bpmLog/abnormalLog',
    method: 'post',
    data: data
  })
}
// 锁定记录
export function pageAbnormalLog(data) {
  return request({
    url: '/bpm/bpmLog/pageAbnormalLog',
    method: 'get',
    params: data
  })
}

// 不分页查询异常操作记录所有数据
export function abnormalLog(data) {
  return request({
    url: '/bpm/bpmLog/abnormalLog',
    method: 'get',
    params: data
  })
}

// 分页查询项目文件记录数据
export function bpmRecordFileHistory(data) {
  return request({
    url: '/bpm/bpmRecordFileHistory',
    method: 'get',
    params: data
  })
}

// 批量新增文件记录数据
export function insertBatch(data) {
  return request({
    url: '/bpm/bpmRecordFileHistory/insertBatch',
    method: 'post',
    data: data
  })
}

// 项目更新
export function updateNewType(data) {
  return request({
    url: '/bpm/bpmRecordFileHistory/updateNewType',
    method: 'put',
    data: data
  })
}




export function nextTaskPersonnelList(query) {
  return request({
    url: '/bpm/bpmRecordProject/nextTaskPersonnelList',
    method: 'GET',
    params: query
  })
}

// 获取指定产品服务商信息
export function getServiceList(query) {
  return request({
    url: '/bpm/bpmRecordProject/getServiceList',
    method: 'GET',
    params: query
  })
}
