import request from '@/utils/request'

// 查询项目列表
export function listProject(query) {
  return request({
    url: '/bpm/bpmProject',
    method: 'get',
    params: query
  })
}
// 获取商标logo图片
export function getTempUrlFromText(textContent) {
  return request({
    url: '/bpm/serviceCenter/getTempUrlFromText?textContent='+textContent,
    method: 'get',
  })
}

// 查询项目详细
export function getProject(id) {
  return request({
    url: '/bpm/bpmProject/' + id,
    method: 'get'
  })
}

// 新建项目
export function addProjects(data) {
  return request({
    url: '/bpm/bpmProject/add',
    method: 'post',
    data: data
  })
}

// 自动生成编号
export function generateCode(type,data) {
  return request({
    url: `/bpm/api/generateCode/${type}`,
    method: 'post',
    data: data
  })
} 

// 新增项目
export function addProject(data) {
  return request({
    url: '/bpm/bpmProject',
    method: 'post',
    data: data
  })
}

// 修改项目
export function updateProject(data) {
  return request({
    url: '/bpm/bpmProject',
    method: 'put',
    data: data
  })
}

// 删除项目
export function delProject(query) {
  return request({
    url: '/bpm/bpmProject/' ,
    method: 'delete',
    params: query
  })
}

// 表单绑定项目
export function bpmProjectForm(data) {
  return request({
    url: '/bpm/bpmProjectForm',
    method: 'post',
    data: data
  })
}

// 获取项目关联表单信息
export function getForms(query) {
  return request({
    url: '/bpm/bpmProjectForm',
    method: 'get',
    params: query
  })
}

// 查询项目详细
export function getDefinition(query) {
  return request({
    url: '/bpm/bpmProject/bpmProjectDefinition',
    method: 'get',
    params: query
  })
}


export function getProjectAndFromByCode(query) {
  return request({
    url: '/bpm/bpmProject/getProjectAndFromByCode',
    method: 'get',
    params: query
  })
}

// 项目复制
export function projectCopy(data) {
  return request({
    url: '/bpm/bpmProject/copy',
    method: 'post',
    data: data
  })
}

// 项目复制
export function findProjectList(params) {
  return request({
    url: '/bpm/bpmProject/findProjectList',
    method: 'get',
    params: params
  })
}