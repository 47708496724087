<template>
  <div class="outer-box">
    <el-badge :max="99" :value="infoNoticeNum" class="dot_item">

    <svg-icon class-name="search-icon" icon-class="email" @click.stop="openNotice" />
     </el-badge>
    <el-drawer title="消息通知" :visible.sync="showNotice" :append-to-body="true" direction="rtl" size="50%">
      <div class="message-container">
        <el-tabs v-model="queryParams.noticeType" type="card" @tab-click="getList">
          <el-tab-pane v-for="(item,index) in dict.type.bpm_record_task_notice_type" :key="index" :label="item.label" :name="item.value">
          </el-tab-pane>

        </el-tabs>
        <div class="message-list">
          <div class="message-header">
            <div style="margin-bottom:20px"> <el-checkbox @change="changeAll" v-model="selectAll" style="margin-right: 20px">全选</el-checkbox>
              <el-button type="primary" size="mini" @click="markAllRead">全部已读</el-button>
            </div>

            <el-switch v-model="queryParams.status" @change="getList" inactive-value="0" :active-value="null" active-text="查看全部" inactive-text="仅看未读"></el-switch>
          </div>
          <el-row class="message-content" :style="{ height: windowHeight + 'px'}">
            <el-card v-for="(message, index) in todoMessages" :key="index" class="message-item"
                     @click.native="goDetail(message)">
              <el-row>
                <el-col :span="1">
                  <el-checkbox v-model="message.checked" @click.native.stop="boxEvent" class="checkbox"></el-checkbox>
                </el-col>
                <el-col :span="18">
                  <div class="message_tle"> <dict-tag :options="dict.type.bpm_record_task_notice_msg_type" :value="message.msgType" /></div>
                  <div class="message_detail">{{ message.noticeTitle }}</div>
                  <div class="message_operator">操作人：{{message.createBy}}</div>

                </el-col>
                <el-col class="message_right" :span="5">
                  <el-tag v-if=" message.status !== '0'" type="success" class="message_status">已读</el-tag>
                  <el-tag v-else class="message_status">未读</el-tag>
                  <div class="message_time">{{ message.createTime }}</div>
                </el-col>
              </el-row>
            </el-card>

          </el-row>
          <el-pagination @size-change="handleSizeChange" @current-change="handlecurrentChange" :page-sizes="[10, 20, 30, 50]" :page-size="queryParams.size" :total="total"
            layout="total, sizes, prev, pager, next, jumper"></el-pagination>
        </div>
      </div>

    </el-drawer>

    <el-dialog :append-to-body="true" :visible.sync="noticeVisible" title="系统通知" @close="noticeVisible = false">
      <p>{{ noticeTitle }}</p>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from "vuex";

import {getSysUserNotice, updateStatus} from "@/api/system/notice";

export default {
  computed:{
     ...mapState({
       infoNoticeNum: state => state.settings.infoNoticeNum
    }),
  },
  dicts: ["bpm_record_task_notice_type", "bpm_record_task_notice_msg_type"],
  data() {
    return {
      windowHeight: 0,
      showNotice: false,
      noticeTitle: null,
      noticeVisible: false,
      selectAll: false,
      showAllMessages: true,
      todoMessages: [
        // 添加更多待办通知消息对象
      ],
      total: null,
      queryParams: {
        size: 10,
        current: 1,
        noticeType: "2",
        status: 0,
      },
    };
  },
  watch: {
    todoMessages: {
      handler(newVal) {
        this.selectAll = newVal.every((item) => item.checked);
      },
      deep: true,
    },
  },
  created() {
    this.handleWindowResize();
    window.addEventListener("resize", this.handleWindowResize);
    this.getList();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleWindowResize);
  },
  methods: {
    handleWindowResize() {
      this.windowHeight = window.innerHeight - 266;
    },
    openNotice() {
      this.showNotice = true;
    },
    async goDetail(message) {
      let res = await updateStatus(message.id);
      if (res.code == 200) {
        if (message.noticeType == 2 && message.noticeUrl) {
          window.open(message.noticeUrl, "_blank");
        } else {
          this.noticeTitle = message.noticeTitle;
          this.noticeVisible = true;
        }
        this.getList();
      }
    },
    changeAll() {
      if (this.selectAll) {
        this.todoMessages.forEach((item) => {
          this.$set(item, "checked", true);
        });
      } else {
        this.todoMessages.forEach((item) => {
          this.$set(item, "checked", false);
        });
      }
    },
    handleSizeChange(val) {
      this.queryParams.size = val;
      this.getList();
    },
    handlecurrentChange(val) {
      this.queryParams.current = val;
      this.getList();
    },
    handleTabClick(tab) {
      this.getList();
    },
    async markAllRead() {
      let arr = this.todoMessages.filter((item) => item.checked);
      console.log(arr);
      if (arr.length > 0) {
        let ids;
        let idsList = arr.map((el) => {
          return el.id;
        });
        ids = idsList.join(",");

        let res = await updateStatus(ids);
        if (res.code == 200) {
          this.$modal.msgSuccess("操作成功");
          this.getList();
        }
      } else {
        this.$modal.msgError("请选择通知");
      }

      // 标记所有消息为已读
    },
    async getList() {
      let res = await getSysUserNotice(this.queryParams);
      this.total = res.data.total;
      this.todoMessages = res.data.records;
      this.todoMessages.forEach((item) => {
        this.$set(item, "checked", false);
      });
    },
    boxEvent() {},
  },
};
</script>

<style lang="scss" scoped>
.message-container {
  background-color: #fff;
  width: 100%;
  margin: 0 auto;
  .message-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .el-checkbox {
      margin-right: 20px;
    }
  }
  .message-list {
    padding: 20px;

    .message-content {
      height: calc(100% - 136px);
      overflow: auto;
      padding: 30px;
      .checkbox {
        margin-top: 5px;
      }
      .message-item {
        cursor: pointer;
        margin-bottom: 20px;
        .message_tle {
          font-weight: 600;
          margin-bottom: 10px;
        }
        .message_detail {
          display: -webkit-box;
          -webkit-line-clamp: 2; /* 显示行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
          font-size: 15px;
        }
        .message_operator {
          color: #aaaaaa;
          font-size: 14px;
          margin-top: 20px;
        }
        .message_right {
          text-align: right;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-items: flex-end;
          min-height: 116px;
          .message_status {
            margin-bottom: 10px;
          }
          .message_time {
            color: #aaaaaa;
            font-size: 14px;
          }
        }
      }
    }
  }
}
.dot_item{
  transform: translateY(10px) scale(.8);
  svg{
  transform: translateY(-10px) scale(1.2);

  }
}
</style>
