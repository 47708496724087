<template>
  <div class="right-board">
    <el-tabs v-model="currentTab" class="center-tabs">
      <el-tab-pane label="组件属性" name="field" />
      <el-tab-pane label="表单属性" name="form" />
    </el-tabs>
    <div class="field-box">
      <a class="document-link" target="_blank" :href="documentLink" title="查看组件文档">
        <i class="el-icon-link" />
      </a>
      <el-scrollbar class="right-scrollbar">
        <!-- 组件属性 -->
        <el-form v-show="currentTab === 'field' && showField" size="small" label-width="90px">
          <!-- <ts-sub-form :active-data="activeData" :fieldComList="fieldComList" /> -->
          <el-form-item v-if="activeData.__config__.changeTag" label="组件类型">
            <el-select v-model="activeData.__config__.tagIcon" placeholder="请选择组件类型" :style="{ width: '100%' }" @change="tagChange">
              <el-option-group v-for="group in tagList" :key="group.label" :label="group.label">
                <el-option v-for="item in group.options" :key="item.__config__.label" :label="item.__config__.label" :value="item.__config__.tagIcon">
                  <svg-icon :icon-class="item.__config__.tagIcon" class="node-icon" />
                  <span> {{ item.__config__.label }}</span>
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item v-if="activeData.__config__.componentName !== undefined" label="组件名">
            <el-input v-model="activeData.__config__.componentName" placeholder="请输入组件名" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.children" label="允许多条">
            <el-switch v-model="activeData.__config__.allowMultiple" />
          </el-form-item>
          <el-form-item v-if="activeData.__vModel__ !== undefined" label="绑定字段">
            <!-- <el-input v-model="activeData.__vModel__" placeholder="请输入字段名（v-model）"/> -->
            <el-select :disabled="!isModule" v-model="activeData.__vModel__" @change="fieldChange" filterable placeholder="请选择">
              <el-option v-for="item in fieldList" :key="item.fieldId" :label="item.name" :value="item.fieldId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="activeData.__config__.label !== undefined" label="标题">
            <el-input v-model="activeData.__config__.label" placeholder="请输入标题" @input="changeRenderKey" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.label !== undefined" label="字段说明">
            <el-input v-model="activeData.__config__.explain" placeholder="请输入字段说明" />
          </el-form-item>
          <!-- <el-form-item v-if="activeData.__config__.label !== undefined" label="字段提示">
            <el-input v-model="activeData.__config__.prompt" placeholder="请输入字段提示" />
          </el-form-item> -->
          <el-form-item v-if="activeData.placeholder!==undefined" label="占位提示">
            <el-input v-model="activeData.placeholder" placeholder="请输入占位提示" @input="changeRenderKey" />
          </el-form-item>
          <!-- <el-form-item v-if="activeData['start-placeholder']!==undefined" label="开始占位">
            <el-input v-model="activeData['start-placeholder']" placeholder="请输入占位提示" />
          </el-form-item>
          <el-form-item v-if="activeData['end-placeholder']!==undefined" label="结束占位">
            <el-input v-model="activeData['end-placeholder']" placeholder="请输入占位提示" />
          </el-form-item> -->
          <el-form-item v-if="activeData.__config__.span !== undefined" label="表单栅格">
            <el-slider v-model="activeData.__config__.span" :marks="{ 12: '' }" :max="24" :min="1" @change="spanChange" />
          </el-form-item>
          <el-form-item v-if="
              activeData.__config__.layout === 'rowFormItem' &&
              activeData.gutter !== undefined
            " label="栅格间隔">
            <el-input-number v-model="activeData.gutter" :min="0" placeholder="栅格间隔" />
          </el-form-item>
          <el-form-item v-if="
              activeData.__config__.layout === 'rowFormItem' &&
              activeData.type !== undefined
            " label="布局模式">
            <el-radio-group v-model="activeData.type">
              <el-radio-button label="default" />
              <el-radio-button label="flex" />
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="
              activeData.justify !== undefined && activeData.type === 'flex'
            " label="水平排列">
            <el-select v-model="activeData.justify" placeholder="请选择水平排列" :style="{ width: '100%' }">
              <el-option v-for="(item, index) in justifyOptions" :key="index" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="activeData.align !== undefined && activeData.type === 'flex'" label="垂直排列">
            <el-radio-group v-model="activeData.align">
              <el-radio-button label="top" />
              <el-radio-button label="middle" />
              <el-radio-button label="bottom" />
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="activeData.__config__.labelWidth !== undefined" label="标签宽度">
            <el-input v-model.number="activeData.__config__.labelWidth" placeholder="请输入标签宽度" type="number" />
          </el-form-item>
          <el-form-item v-if="activeData.style && activeData.style.width !== undefined" label="组件宽度">
            <el-input v-model="activeData.style.width" placeholder="请输入组件宽度" clearable />
          </el-form-item>
          <el-form-item v-if="activeData.__vModel__!==undefined" label="默认值">
            <el-input :value="setDefaultValue(activeData.__config__.defaultValue)" placeholder="请输入默认值" @input="onDefaultValueInput" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag==='el-checkbox-group'" label="至少应选">
            <el-input-number :value="activeData.min" :min="0" placeholder="至少应选" @input="$set(activeData, 'min', $event?$event:undefined)" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag==='el-checkbox-group'" label="最多可选">
            <el-input-number :value="activeData.max" :min="0" placeholder="最多可选" @input="$set(activeData, 'max', $event?$event:undefined)" />
          </el-form-item>
          <el-form-item v-if="activeData.__slot__&&activeData.__slot__.prepend!==undefined" label="前缀">
            <el-input v-model="activeData.__slot__.prepend" placeholder="请输入前缀" />
          </el-form-item>
          <el-form-item v-if="activeData.__slot__&&activeData.__slot__.append!==undefined" label="后缀">
            <el-input v-model="activeData.__slot__.append" placeholder="请输入后缀" />
          </el-form-item>
          <el-form-item v-if="activeData['prefix-icon']!==undefined" label="前图标">
            <el-input v-model="activeData['prefix-icon']" placeholder="请输入前图标名称">
              <el-button slot="append" icon="el-icon-thumb" @click="openIconsDialog('prefix-icon')">
                选择
              </el-button>
            </el-input>
          </el-form-item>
          <el-form-item v-if="activeData['suffix-icon'] !== undefined" label="后图标">
            <el-input v-model="activeData['suffix-icon']" placeholder="请输入后图标名称">
              <el-button slot="append" icon="el-icon-thumb" @click="openIconsDialog('suffix-icon')">
                选择
              </el-button>
            </el-input>
          </el-form-item>
          <el-form-item v-if="activeData['icon']!==undefined && activeData.__config__.tag === 'el-button'" label="按钮图标">
            <el-input v-model="activeData['icon']" placeholder="请输入按钮图标名称">
              <el-button slot="append" icon="el-icon-thumb" @click="openIconsDialog('icon')">
                选择
              </el-button>
            </el-input>
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag === 'el-cascader'" label="选项分隔符">
            <el-input v-model="activeData.separator" placeholder="请输入选项分隔符" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag === 'el-cascader'" label="限制数量">
            <el-input-number v-model="activeData.limit" :min="0" placeholder="限制数量" />
          </el-form-item>
          <el-form-item v-if="activeData.autosize !== undefined" label="最小行数">
            <el-input-number v-model="activeData.autosize.minRows" :min="1" placeholder="最小行数" />
          </el-form-item>
          <el-form-item v-if="activeData.autosize !== undefined" label="最大行数">
            <el-input-number v-model="activeData.autosize.maxRows" :min="1" placeholder="最大行数" />
          </el-form-item>
          <el-form-item v-if="isShowMin" label="最小值">
            <el-input-number v-model="activeData.min" placeholder="最小值" />
          </el-form-item>
          <el-form-item v-if="isShowMax" label="最大值">
            <el-input-number v-model="activeData.max" placeholder="最大值" />
          </el-form-item>
          <el-form-item v-if="activeData.height!==undefined" label="组件高度">
            <el-input-number v-model="activeData.height" placeholder="高度" @input="changeRenderKey" />
          </el-form-item>
          <el-form-item v-if="isShowStep" label="步长">
            <el-input-number v-model="activeData.step" placeholder="步数" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag === 'el-input-number'" label="精度">
            <el-input-number v-model="activeData.precision" :min="0" placeholder="精度" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag === 'el-input-number'" label="按钮位置">
            <el-radio-group v-model="activeData['controls-position']">
              <el-radio-button label="">
                默认
              </el-radio-button>
              <el-radio-button label="right">
                右侧
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="activeData.maxlength !== undefined" label="最多输入">
            <el-input v-model="activeData.maxlength" placeholder="请输入字符长度">
              <template slot="append">
                个字符
              </template>
            </el-input>
          </el-form-item>
          <el-form-item v-if="activeData['active-text'] !== undefined" label="开启提示">
            <el-input v-model="activeData['active-text']" placeholder="请输入开启提示" />
          </el-form-item>
          <el-form-item v-if="activeData['inactive-text'] !== undefined" label="关闭提示">
            <el-input v-model="activeData['inactive-text']" placeholder="请输入关闭提示" />
          </el-form-item>
          <el-form-item v-if="activeData['active-value'] !== undefined" label="开启值">
            <el-input :value="setDefaultValue(activeData['active-value'])" placeholder="请输入开启值" @input="onSwitchValueInput($event, 'active-value')" />
          </el-form-item>
          <el-form-item v-if="activeData['inactive-value'] !== undefined" label="关闭值">
            <el-input :value="setDefaultValue(activeData['inactive-value'])" placeholder="请输入关闭值" @input="onSwitchValueInput($event, 'inactive-value')" />
          </el-form-item>
          <el-form-item v-if="
              activeData.type !== undefined &&
              'el-date-picker' === activeData.__config__.tag
            " label="时间类型">
            <el-select v-model="activeData.type" placeholder="请选择时间类型" :style="{ width: '100%' }" @change="dateTypeChange">
              <el-option v-for="(item, index) in dateOptions" :key="index" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="activeData.__config__.isInfo" label="绑定信息表">
            <el-select v-model="activeData.__config__.infoId" placeholder="请选择信息表" :style="{ width: '100%' }" clearable>
              <el-option v-for="(item,index) in infoList" :key="index" :label="item.templateName" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="activeData.__config__.isInfo" label="信息表名称">
            <el-input v-model="activeData.__config__.infoName" placeholder="请输入信息表名称" />
          </el-form-item>
          <el-form-item v-if="activeData.name !== undefined" label="文件字段名">
            <el-input v-model="activeData.name" placeholder="请输入上传文件字段名" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag === 'el-upload'" label="文件类型">
            <el-select v-model="activeData.actionType" placeholder="请选择文件类型" :style="{ width: '100%' }" clearable>
              <el-option v-for="dict in dict.type.action_file_type" :key="dict.value" :label="dict.label" :value="dict.value" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="activeData.__config__.limit !== undefined" label="数量限制">
            <el-input type="number" v-model="activeData.__config__.limit" placeholder="请输入上传文件数量限制" />
          </el-form-item>
          <!-- <el-form-item v-if="activeData.accept !== undefined" label="文件类型">
            <el-select v-model="activeData.accept" placeholder="请选择文件类型" :style="{ width: '100%' }" clearable>
              <el-option label="图片" value="image/*" />
              <el-option label="视频" value="video/*" />
              <el-option label="音频" value="audio/*" />
              <el-option label="excel" value=".xls,.xlsx" />
              <el-option label="word" value=".doc,.docx" />
              <el-option label="pdf" value=".pdf" />
              <el-option label="txt" value=".txt" />
            </el-select>
          </el-form-item> -->
          <el-form-item v-if="activeData.__config__.fileSize !== undefined" label="文件大小">
            <el-input v-model.number="activeData.__config__.fileSize" placeholder="请输入文件大小">
              <el-select slot="append" v-model="activeData.__config__.sizeUnit" :style="{ width: '66px' }">
                <el-option label="KB" value="KB" />
                <el-option label="MB" value="MB" />
                <el-option label="GB" value="GB" />
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item v-if="activeData.action !== undefined" label="上传地址">
            <el-input v-model="activeData.action" placeholder="请输入上传地址" clearable />
          </el-form-item>
          <el-form-item v-if="activeData.downAction !== undefined" label="下载地址">
            <el-input v-model="activeData.downAction" placeholder="请输入下载地址" clearable>
              <el-select v-model="activeData.downMethod" slot="append" placeholder="请求方式" style="width:76px">
                <el-option label="get" value="get"></el-option>
                <el-option label="post" value="post"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item v-if="activeData.downParameter !== undefined" label="下载参数">
            <el-input v-model="activeData.downParameter" placeholder="请输入下载参数" clearable />
          </el-form-item>
          <el-form-item v-if="activeData['list-type'] !== undefined" label="列表类型">
            <el-radio-group v-model="activeData['list-type']" size="small">
              <el-radio-button label="text"> text </el-radio-button>
              <el-radio-button label="picture"> picture </el-radio-button>
              <el-radio-button label="picture-card">
                picture-card
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="
              activeData.type !== undefined &&
              activeData.__config__.tag === 'el-button'
            " label="按钮类型">
            <el-select v-model="activeData.type" :style="{ width: '100%' }">
              <el-option label="primary" value="primary" />
              <el-option label="success" value="success" />
              <el-option label="warning" value="warning" />
              <el-option label="danger" value="danger" />
              <el-option label="info" value="info" />
              <el-option label="text" value="text" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="activeData.__config__.buttonText !== undefined" v-show="'picture-card' !== activeData['list-type']" label="按钮文字">
            <el-input v-model="activeData.__config__.buttonText" placeholder="请输入按钮文字" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag === 'el-button'" label="按钮文字">
            <el-input v-model="activeData.__slot__.default" placeholder="请输入按钮文字" />
          </el-form-item>
          <el-form-item v-if="activeData['range-separator'] !== undefined" label="分隔符">
            <el-input v-model="activeData['range-separator']" placeholder="请输入分隔符" />
          </el-form-item>
          <el-form-item v-if="activeData['picker-options'] !== undefined" label="时间段">
            <el-input v-model="activeData['picker-options'].selectableRange" placeholder="请输入时间段" />
          </el-form-item>
          <el-form-item v-if="activeData.format !== undefined" label="时间格式">
            <el-input :value="activeData.format" placeholder="请输入时间格式" @input="setTimeValue($event)" />
          </el-form-item>
          <template v-if="
              ['el-checkbox-group', 'el-radio-group', 'el-select'].indexOf(
                activeData.__config__.tag
              ) > -1
            ">
            <el-divider>选项</el-divider>
            <draggable :list="activeData.__slot__.options" :animation="340" group="selectItem" handle=".option-drag">
              <div v-for="(item, index) in activeData.__slot__.options" :key="index" class="select-item">
                <div class="select-line-icon option-drag">
                  <i class="el-icon-s-operation" />
                </div>
                <el-input v-model="item.label" placeholder="选项名" size="small" />
                <el-input disabled placeholder="选项值" size="small" :value="item.value" @input="setOptionValue(item, $event)" />
                <div class="close-btn select-line-icon" @click="activeData.__slot__.options.splice(index, 1)">
                  <i class="el-icon-remove-outline" />
                </div>
              </div>
            </draggable>
            <div style="margin-left: 20px">
              <el-button style="padding-bottom: 0" icon="el-icon-circle-plus-outline" type="text" @click="addSelectItem">
                添加选项
              </el-button>
              <el-button style="padding-bottom: 0" icon="el-icon-circle-plus-outline" type="text" @click="addSelectItemOther">
                添加其他
              </el-button>
            </div>
            <el-divider />
          </template>

          <template v-if="
              ['el-cascader', 'el-table'].includes(activeData.__config__.tag)
            ">
            <el-divider>选项</el-divider>
            <el-form-item v-if="activeData.__config__.dataType" label="数据类型">
              <el-radio-group v-model="activeData.__config__.dataType" size="small">
                <el-radio-button label="dynamic"> 动态数据 </el-radio-button>
                <el-radio-button label="static"> 静态数据 </el-radio-button>
              </el-radio-group>
            </el-form-item>

            <template v-if="activeData.__config__.dataType === 'dynamic'">
              <el-form-item label="接口地址">
                <el-input v-model="activeData.__config__.url" :title="activeData.__config__.url" clearable placeholder="请输入接口地址" @blur="$emit('fetch-data', activeData)">
                  <el-select slot="prepend" v-model="activeData.__config__.method" :style="{ width: '85px' }" @change="$emit('fetch-data', activeData)">
                    <el-option label="get" value="get" />
                    <el-option label="post" value="post" />
                    <el-option label="put" value="put" />
                    <el-option label="delete" value="delete" />
                  </el-select>
                </el-input>
              </el-form-item>
              <el-form-item label="数据位置">
                <el-input v-model="activeData.__config__.dataPath" placeholder="请输入数据位置" @blur="$emit('fetch-data', activeData)" />
              </el-form-item>

              <template v-if="activeData.props && activeData.props.props">
                <el-form-item label="标签键名">
                  <el-input v-model="activeData.props.props.label" placeholder="请输入标签键名" />
                </el-form-item>
                <el-form-item label="值键名">
                  <el-input v-model="activeData.props.props.value" placeholder="请输入值键名" />
                </el-form-item>
                <el-form-item label="子级键名">
                  <el-input v-model="activeData.props.props.children" placeholder="请输入子级键名" />
                </el-form-item>
              </template>
            </template>

            <!-- 级联选择静态树 -->
            <el-tree v-if="activeData.__config__.dataType === 'static'" draggable :data="activeData.options" node-key="id" :expand-on-click-node="false" :render-content="renderContent" />
            <div v-if="activeData.__config__.dataType === 'static'" style="margin-left: 20px">
              <el-button style="padding-bottom: 0" icon="el-icon-circle-plus-outline" type="text" @click="addTreeItem">
                添加父级
              </el-button>
            </div>
            <el-divider />
          </template>

          <el-form-item v-if="activeData.__config__.optionType !== undefined" label="选项样式">
            <el-radio-group v-model="activeData.__config__.optionType">
              <el-radio-button label="default"> 默认 </el-radio-button>
              <el-radio-button label="button"> 按钮 </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="activeData['active-color'] !== undefined" label="开启颜色">
            <el-color-picker v-model="activeData['active-color']" />
          </el-form-item>
          <el-form-item v-if="activeData['inactive-color'] !== undefined" label="关闭颜色">
            <el-color-picker v-model="activeData['inactive-color']" />
          </el-form-item>
          <el-form-item v-if="activeData.conversion !== undefined" label="支持金额大写转换">
            <el-switch v-model="activeData.conversion" />
          </el-form-item>
          <el-form-item v-if="
              activeData.__config__.showLabel !== undefined &&
              activeData.__config__.labelWidth !== undefined
            " label="显示标签">
            <el-switch v-model="activeData.__config__.showLabel" />
          </el-form-item>
          <el-form-item v-if="activeData.branding !== undefined" label="品牌烙印">
            <el-switch v-model="activeData.branding" @input="changeRenderKey" />
          </el-form-item>
          <el-form-item v-if="activeData['allow-half'] !== undefined" label="允许半选">
            <el-switch v-model="activeData['allow-half']" />
          </el-form-item>
          <el-form-item v-if="activeData['show-text'] !== undefined" label="辅助文字">
            <el-switch v-model="activeData['show-text']" @change="rateTextChange" />
          </el-form-item>
          <el-form-item v-if="activeData['show-score'] !== undefined" label="显示分数">
            <el-switch v-model="activeData['show-score']" @change="rateScoreChange" />
          </el-form-item>
          <el-form-item v-if="activeData['show-stops'] !== undefined" label="显示间断点">
            <el-switch v-model="activeData['show-stops']" />
          </el-form-item>
          <el-form-item v-if="activeData.range !== undefined" label="范围选择">
            <el-switch v-model="activeData.range" @change="rangeChange" />
          </el-form-item>
          <el-form-item v-if="
              activeData.__config__.border !== undefined &&
              activeData.__config__.optionType === 'default'
            " label="是否带边框">
            <el-switch v-model="activeData.__config__.border" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag === 'el-color-picker'" label="颜色格式">
            <el-select v-model="activeData['color-format']" placeholder="请选择颜色格式" :style="{ width: '100%' }" clearable @change="colorFormatChange">
              <el-option v-for="(item, index) in colorFormatOptions" :key="index" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="
              activeData.size !== undefined &&
              (activeData.__config__.optionType === 'button' ||
                activeData.__config__.border ||
                activeData.__config__.tag === 'el-color-picker' ||
                activeData.__config__.tag === 'el-button')
            " label="组件尺寸">
            <el-radio-group v-model="activeData.size">
              <el-radio-button label="medium"> 中等 </el-radio-button>
              <el-radio-button label="small"> 较小 </el-radio-button>
              <el-radio-button label="mini"> 迷你 </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="activeData['show-word-limit'] !== undefined" label="输入统计">
            <el-switch v-model="activeData['show-word-limit']" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag === 'el-input-number'" label="严格步数">
            <el-switch v-model="activeData['step-strictly']" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag === 'el-cascader'" label="任选层级">
            <el-switch v-model="activeData.props.props.checkStrictly" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag === 'el-cascader'" label="是否多选">
            <el-switch v-model="activeData.props.props.multiple" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag === 'el-cascader'" label="展示全路径">
            <el-switch v-model="activeData['show-all-levels']" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag === 'el-cascader'" label="可否筛选">
            <el-switch v-model="activeData.filterable" />
          </el-form-item>
          <el-form-item v-if="activeData.clearable !== undefined" label="能否清空">
            <el-switch v-model="activeData.clearable" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.showTip !== undefined" label="显示提示">
            <el-switch v-model="activeData.__config__.showTip" />
          </el-form-item>
          <el-form-item v-if="
              activeData.__config__.tag === 'el-upload' &&
              !activeData.__config__.isIdentify&&
              !activeData.__config__.isFillin
            " label="多选文件">
            <el-switch v-model="activeData.multiple" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.isIdentify" label="识别类型">
            <el-select @change="handleIdentifyType" v-model="activeData.__config__.identifyType" placeholder="请选择类型">
              <el-option v-for="type in identifyTypes" :key="type.value" :label="type.label" :value="type.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="activeData.__config__.isFillin" label="识别类型">
            <el-select @change="handleFillinType" v-model="activeData.__config__.identifyType" placeholder="请选择类型">
              <el-option v-for="dict in dict.type.bpm_config_data_record" :key="dict.value" :label="dict.label" :value="dict.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="activeData.__config__.isIdentify || 
              activeData.__config__.isFillin" label="识别映射">
            <el-button type="primary" @click="showIdentifyDialog(activeData.__config__.isFillin,activeData.__config__.identifyType)">映射字段</el-button>
          </el-form-item>
          <el-form-item v-if="activeData['auto-upload'] !== undefined" label="自动上传">
            <el-switch v-model="activeData['auto-upload']" />
          </el-form-item>
          <el-form-item v-if="activeData.readonly !== undefined" label="是否只读">
            <el-switch v-model="activeData.readonly" />
          </el-form-item>
          <!-- <el-form-item v-if="activeData.disabled !== undefined" label="是否禁用">
            <el-switch v-model="activeData.disabled" />
          </el-form-item> -->
          <el-form-item v-if="activeData.__config__.tag === 'el-select'" label="能否搜索">
            <el-switch v-model="activeData.filterable" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag === 'el-select'" label="是否多选">
            <el-switch v-model="activeData.multiple" @change="multipleChange" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.required !== undefined" label="是否必填">
            <el-switch v-model="activeData.__config__.required" />
          </el-form-item>
          <!-- 是单选框  再 判断 是不是 商标logo生成组件 -->
          <!-- <el-form-item v-if="activeData.__config__.tag === 'el-radio-group'" label="logo生成">
            <el-switch v-model="activeData.__config__.isAuto" />
          </el-form-item> -->
          <!-- 是上传框 、单选框、 输入框 再 判断 是不是 商标logo生成组件 -->
          <el-form-item v-if="activeData.__config__.tag === 'el-upload'||activeData.__config__.tag === 'el-radio-group'||activeData.__config__.tag === 'el-input'" label="logo生成">
            <el-switch v-model="activeData.__config__.isAuto" />
          </el-form-item>

          <template v-if="activeData.__config__.layoutTree">
            <el-divider>布局结构树</el-divider>
            <el-tree :data="[activeData.__config__]" :props="layoutTreeProps" node-key="renderKey" default-expand-all draggable>
              <span slot-scope="{ node, data }">
                <span class="node-label">
                  <svg-icon :icon-class="
                      data.__config__ ? data.__config__.tagIcon : data.tagIcon
                    " class="node-icon" />
                  {{ node.label }}
                </span>
              </span>
            </el-tree>
          </template>

          <template v-if="Array.isArray(activeData.__config__.regList)">
            <el-divider>正则校验</el-divider>
            <div v-for="(item, index) in activeData.__config__.regList" :key="index" class="reg-item">
              <span class="close-btn" @click="activeData.__config__.regList.splice(index, 1)">
                <i class="el-icon-close" />
              </span>
              <el-form-item label="表达式">
                <el-input v-model="item.pattern" placeholder="请输入正则" />
              </el-form-item>
              <el-form-item label="错误提示" style="margin-bottom: 0">
                <el-input v-model="item.message" placeholder="请输入错误提示" />
              </el-form-item>
            </div>
            <div style="margin-left: 20px">
              <el-button icon="el-icon-circle-plus-outline" type="text" @click="addReg">
                添加规则
              </el-button>
            </div>
          </template>
          <template v-if="Array.isArray(activeData.__config__.autoList)">
            <el-divider>编号规则</el-divider>
            <draggable :list="activeData.__config__.autoList" :animation="340" group="selectItem" handle=".option-drag">
              <div style="width:100%;display: flex;align-items: center;justify-content: space-around;margin: 6px 0;" v-for="(item, index) in activeData.__config__.autoList" :key="index" class="select-item">
                <div class="select-line-icon option-drag">
                  <i style="font-size:20px;cursor:move;" class="el-icon-s-operation" />
                </div>

                <el-form-item style="width:250px;margin:0;" :label="item.label" v-if="item.label !== '引用字段'">
                  <el-input v-model="item.message" :placeholder="'请输入'+item.label" :disabled="item.label == '创建时间'" />
                </el-form-item>
                <el-form-item style="width:250px;margin:0;" :label="item.label" v-else>
                  <el-select v-model="item.pattern" @change="fieldAutoChange($event,index)" filterable placeholder="请选择">
                    <el-option v-for="field in fieldList" :key="field.fieldId" :label="field.name" :value="field.fieldId">
                    </el-option>
                  </el-select>
                </el-form-item>
                <div class="close-btn select-line-icon" v-if="item.label != '编号'&&item.label != '创建时间'" @click="activeData.__config__.autoList.splice(index, 1)">
                  <i style="font-size:20px;color:#f56c6c;cursor:pointer;" class="el-icon-remove-outline" />
                </div>
                <div class="close-btn select-line-icon" style="width:28px" v-else>
                  <!-- <i style="font-size:20px;color:#f56c6c;cursor:pointer;" class="el-icon-remove-outline" /> -->
                </div>
              </div>
            </draggable>

            <div style="margin-left: 20px">
              <el-dropdown @command="addAuto" trigger="click">
                <span class="el-dropdown-link">
                  添加规则<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="固定字段">固定字段</el-dropdown-item>
                  <el-dropdown-item command="引用字段">引用字段</el-dropdown-item>
                  <!-- <el-dropdown-item  command="创建时间">创建时间</el-dropdown-item> -->
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-form>
        <!-- 表单属性 -->
        <el-form v-show="currentTab === 'form'" size="small" label-width="90px">
          <el-form-item label="表单名">
            <el-input v-model="formConf.formRef" placeholder="请输入表单名（ref）" />
          </el-form-item>
          <el-form-item label="表单模型">
            <el-input v-model="formConf.formModel" placeholder="请输入数据模型" />
          </el-form-item>
          <el-form-item label="校验模型">
            <el-input v-model="formConf.formRules" placeholder="请输入校验模型" />
          </el-form-item>
          <el-form-item label="表单尺寸">
            <el-radio-group v-model="formConf.size">
              <el-radio-button label="medium"> 中等 </el-radio-button>
              <el-radio-button label="small"> 较小 </el-radio-button>
              <el-radio-button label="mini"> 迷你 </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="标签对齐">
            <el-radio-group v-model="formConf.labelPosition">
              <el-radio-button label="left"> 左对齐 </el-radio-button>
              <el-radio-button label="right"> 右对齐 </el-radio-button>
              <el-radio-button label="top"> 顶部对齐 </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="标签宽度">
            <el-input v-model.number="formConf.labelWidth" placeholder="请输入标签宽度" type="number" />
          </el-form-item>
          <el-form-item label="栅格间隔">
            <el-input-number v-model="formConf.gutter" :min="0" placeholder="栅格间隔" />
          </el-form-item>
          <el-form-item label="禁用表单">
            <el-switch v-model="formConf.disabled" />
          </el-form-item>
          <el-form-item label="表单按钮">
            <el-switch v-model="formConf.formBtns" />
          </el-form-item>
          <el-form-item label="显示未选中组件边框">
            <el-switch v-model="formConf.unFocusedComponentBorder" />
          </el-form-item>
        </el-form>
      </el-scrollbar>
    </div>

    <treeNode-dialog :visible.sync="dialogVisible" title="添加选项" @commit="addNode" />
    <icons-dialog :visible.sync="iconsVisible" :current="activeData[currentIconModel]" @select="setIcon" />
    <identify-dialog :identifyList="activeData.__config__.identifyList" :visible.sync="identifyVisible" @enSureIdentify="enSureIdentify" :drawingList="drawingList" :type="activeData.__config__.identifyType"
      title="字段映射"></identify-dialog>

    <datafill-dialog :identifyList="activeData.__config__.identifyList" :visible.sync="datafillVisible" :typeList="typeList" @enSureIdentify="enSureIdentify" :drawingList="drawingList"
      :type="activeData.__config__.identifyType" title="字段映射"></datafill-dialog>
  </div>
</template>

<script>
import { getVModel } from "@/api/system/config";
import { getTeomplateFilesByProjectCode } from "@/api/system/config";
import draggable from "vuedraggable";
import { isArray } from "util";
import TreeNodeDialog from "./TreeNodeDialog";
import { isNumberStr } from "@/utils";
import IconsDialog from "./IconsDialog";
import IdentifyDialog from "./IdentifyDialog";
import datafillDialog from "./DataFillDialog";
import { inputComponents, selectComponents } from "@/utils/generator/config";
import tsSubForm from "@/views/tool/build/panelProperties/ts-sub-form.vue";
import { fieldSearch } from "@/api/project/field";
import { log } from "console";
const dateTimeFormat = {
  date: "yyyy-MM-dd",
  week: "yyyy 第 WW 周",
  month: "yyyy-MM",
  year: "yyyy",
  datetime: "yyyy-MM-dd HH:mm:ss",
  daterange: "yyyy-MM-dd",
  monthrange: "yyyy-MM",
  datetimerange: "yyyy-MM-dd HH:mm:ss",
};

// 使changeRenderKey在目标组件改变时可用
const needRerenderList = ["tinymce"];

export default {
  components: {
    TreeNodeDialog,
    IconsDialog,
    tsSubForm,
    draggable,
    IdentifyDialog,
    datafillDialog,
  },
  dicts: ["bpm_config_data_record", "action_file_type"],
  props: [
    "showField",
    "activeData",
    "formConf",
    "drawingList",
    "fieldComList",
    "areaGroupId",
    "isModule"
  ],
  data() {
    return {
      typeList: [],
      datafillVisible: false,
      infoList: [],
      projectCode: null,
      currentTab: "field",
      currentNode: null,
      dialogVisible: false,
      iconsVisible: false,
      identifyVisible: false,
      currentIconModel: null,
      dateTypeOptions: [
        {
          label: "日(date)",
          value: "date",
        },
        {
          label: "周(week)",
          value: "week",
        },
        {
          label: "月(month)",
          value: "month",
        },
        {
          label: "年(year)",
          value: "year",
        },
        {
          label: "日期时间(datetime)",
          value: "datetime",
        },
      ],
      dateRangeTypeOptions: [
        {
          label: "日期范围(daterange)",
          value: "daterange",
        },
        {
          label: "月范围(monthrange)",
          value: "monthrange",
        },
        {
          label: "日期时间范围(datetimerange)",
          value: "datetimerange",
        },
      ],
      colorFormatOptions: [
        {
          label: "hex",
          value: "hex",
        },
        {
          label: "rgb",
          value: "rgb",
        },
        {
          label: "rgba",
          value: "rgba",
        },
        {
          label: "hsv",
          value: "hsv",
        },
        {
          label: "hsl",
          value: "hsl",
        },
      ],
      justifyOptions: [
        {
          label: "start",
          value: "start",
        },
        {
          label: "end",
          value: "end",
        },
        {
          label: "center",
          value: "center",
        },
        {
          label: "space-around",
          value: "space-around",
        },
        {
          label: "space-between",
          value: "space-between",
        },
      ],
      identifyTypes: [
        { label: "多证件识别", value: -1 },
        { label: "营业执照", value: 1 },
        { label: "身份证人像", value: 2 },
        { label: "身份证国徽", value: 3 },
        { label: "中国大陆护照", value: 4 },
        { label: "通行证", value: 5 },
        { label: "委托书", value: 6 },
        { label: "身份证正反面", value: 7 },
      ],
      layoutTreeProps: {
        label(data, node) {
          const config = data.__config__;
          return data.componentName || `${config.label}: ${data.__vModel__}`;
        },
      },
      fieldList: [],
    };
  },
  computed: {
    documentLink() {
      return (
        this.activeData.__config__.document ||
        "https://element.eleme.cn/#/zh-CN/component/installation"
      );
    },
    dateOptions() {
      if (
        this.activeData.type !== undefined &&
        this.activeData.__config__.tag === "el-date-picker"
      ) {
        if (this.activeData["start-placeholder"] === undefined) {
          return this.dateTypeOptions;
        }
        return this.dateRangeTypeOptions;
      }
      return [];
    },
    tagList() {
      return [
        {
          label: "输入型组件",
          options: inputComponents,
        },
        {
          label: "选择型组件",
          options: selectComponents,
        },
      ];
    },
    activeTag() {
      return this.activeData.__config__.tag;
    },
    isShowMin() {
      return ["el-input-number", "el-slider"].indexOf(this.activeTag) > -1;
    },
    isShowMax() {
      return (
        ["el-input-number", "el-slider", "el-rate"].indexOf(this.activeTag) > -1
      );
    },
    isShowStep() {
      return ["el-input-number", "el-slider"].indexOf(this.activeTag) > -1;
    },
  },
  watch: {
    formConf: {
      handler(val) {},
      deep: true,
    },
  },
  created() {
    console.log("areaGroupId 第二部", this.areaGroupId);
    if (this.$route.query.projectCode) {
      this.projectCode = this.$route.query.projectCode;
      this.getInfoList();
    }
    fieldSearch({}).then((res) => {
      this.fieldList = JSON.parse(JSON.stringify(res.data));
    });
  },
  methods: {
    async getInfoList() {
      let res = await getTeomplateFilesByProjectCode({
        projectCode: this.projectCode,
      });
      this.infoList = res.data;
    },
    enSureIdentify(List) {
      console.log(this.activeData);
      this.activeData.__config__.identifyList = List;
      this.identifyVisible = false;
      this.datafillVisible = false;
      this.$modal.msgSuccess("保存成功");
    },
    showIdentifyDialog(type, num) {
      if (type) {
        this.datafillVisible = true;
      } else {
        this.identifyVisible = true;
      }
    },
    fieldChange(value) {
      console.log("fieldChange", this.activeData, this.formConf);
      const form = this.formConf.fields.find((item) =>
        item.__config__.children.some(
          (child) =>
            child.__config__.renderKey === this.activeData.__config__.renderKey
        )
      );
      if (form) {
        const list = form.__config__.children;
        const list1 = list.filter((item) => item.__vModel__ === value);
        if (list1.length === 2) {
          this.$message.error("请勿重复绑定字段！");
          this.activeData.__vModel__ = null;
          this.activeData.__config__.label = "请绑定字段";
        } else {
          this.activeData.__config__.label =
            this.fieldList.find((item) => item.fieldId === value)?.name || "";
          this.activeData.originalName =
            this.fieldList.find((item) => item.fieldId === value)?.name || "";
        }
        if (this.activeData.__config__.isAuto) {
          this.activeData.__config__.autoList.forEach((item) => {
            item.areaGroupId = this.areaGroupId;
          });
        }
      }
      console.log(value);
    },
    fieldAutoChange(value, index) {
      console.log("value", value);
      console.log(this.activeData, "fieldAutoChange", this.formConf);
      const form = this.formConf.fields.find((item) =>
        item.__config__.children.some(
          (child) =>
            child.__config__.renderKey === this.activeData.__config__.renderKey
        )
      );
      if (form) {
        const list = this.activeData.__config__.autoList;
        // 转换引用字段的值为id 避免选中值跟文字不匹配问题
        this.activeData.__config__.autoList.forEach((listItem) => {
          this.fieldList.forEach((fieldItem) => {
            if (
              listItem.pattern == fieldItem.fieldId ||
              listItem.pattern == fieldItem.name
            ) {
              listItem.pattern = fieldItem.fieldId;
              listItem.__vmodel__ = "";
              listItem.__vmodel__ = fieldItem.fieldId;
              console.log("listItem", listItem);
            }
          });
        });
        console.log(
          "this.activeData.__config__.autoList",
          this.activeData.__config__.autoList
        );
        const list1 = this.activeData.__config__.autoList.filter(
          (item) => item.pattern === value
        );
        console.log("list1", list1);
        if (list1.length === 2) {
          // 有重复绑定字段
          this.$message.error("请勿重复绑定字段！");
          let autoObj1 = {
            pattern: null,
            label: list[index].label,
            message: "",
            __vmodel__: "",
            areaGroupId: this.areaGroupId,
          };
          this.$set(list, index, autoObj1);
        } else {
          // 没有重复绑定字段
          let autoObj2 = {
            pattern:
              this.fieldList.find((item) => item.fieldId === value)?.name || "",
            label: list[index].label,
            message: "",
            __vmodel__:
              this.fieldList.find((item) => item.fieldId === value)?.fieldId ||
              "",
            areaGroupId: this.areaGroupId,
          };
          this.$set(list, index, autoObj2);
        }
      }
    },
    addReg() {
      this.activeData.__config__.regList.push({
        pattern: "",
        message: "",
      });
    },
    addAuto(command) {
      console.log("选中规则", command);
      this.activeData.__config__.autoList.push({
        pattern: "",
        label: command,
        message: "",
        __vmodel__: "",
        areaGroupId: this.areaGroupId,
      });
      console.log("添加后的数组", this.activeData.__config__.autoList);
    },

    addSelectItem() {
      console.log(this.activeData, "this.activeDatathis.activeData");
      if (
        this.activeData.__slot__.options &&
        this.activeData.__slot__.options.length
      ) {
        let addValue
        if(this.activeData.__slot__.options.filter(item => typeof item.value === "number").length){
          const maxValue = Math.max(...this.activeData.__slot__.options.filter(item => typeof item.value === "number").map(item => item.value));
          addValue = maxValue + 1
        } else {
          addValue = 0
        }
        this.activeData.__slot__.options.push({
          label: "",
          value: addValue,
        });
      } else {
        let list = [{ label: "", value: 0 }];
        this.$set(this.activeData.__slot__, "options", list);
      }
    },
    addSelectItemOther() {
      if (
        this.activeData.__slot__.options &&
        this.activeData.__slot__.options.length
      ) {
        this.activeData.__slot__.options.push({
          label: "其他",
          value: -1,
        });
      } else {
        let list = [{ label: "其他", value: -1 }];
        this.$set(this.activeData.__slot__, "options", list);
      }
    },
    addTreeItem() {
      ++this.idGlobal;
      this.dialogVisible = true;
      this.currentNode = this.activeData.options;
    },
    renderContent(h, { node, data, store }) {
      return (
        <div class="custom-tree-node">
          <span>{node.label}</span>
          <span class="node-operation">
            <i
              on-click={() => this.append(data)}
              class="el-icon-plus"
              title="添加"
            ></i>
            <i
              on-click={() => this.remove(node, data)}
              class="el-icon-delete"
              title="删除"
            ></i>
          </span>
        </div>
      );
    },
    append(data) {
      if (!data.children) {
        this.$set(data, "children", []);
      }
      this.dialogVisible = true;
      this.currentNode = data.children;
    },
    remove(node, data) {
      this.activeData.__config__.defaultValue = []; // 避免删除时报错
      const { parent } = node;
      const children = parent.data.children || parent.data;
      const index = children.findIndex((d) => d.id === data.id);
      children.splice(index, 1);
    },
    addNode(data) {
      this.currentNode.push(data);
    },
    setOptionValue(item, val) {
      item.value = isNumberStr(val) ? +val : val;
    },
    setDefaultValue(val) {
      if (Array.isArray(val)) {
        return val.join(",");
      }
      if (["string", "number"].indexOf(val) > -1) {
        return val;
      }
      if (typeof val === "boolean") {
        return `${val}`;
      }
      return val;
    },
    onDefaultValueInput(str) {
      if (isArray(this.activeData.__config__.defaultValue)) {
        // 数组
        this.$set(
          this.activeData.__config__,
          "defaultValue",
          str.split(",").map((val) => (isNumberStr(val) ? +val : val))
        );
      } else if (["true", "false"].indexOf(str) > -1) {
        // 布尔
        this.$set(this.activeData.__config__, "defaultValue", JSON.parse(str));
      } else {
        // 字符串和数字
        this.$set(
          this.activeData.__config__,
          "defaultValue",
          isNumberStr(str) ? +str : str
        );
      }
    },
    onSwitchValueInput(val, name) {
      if (["true", "false"].indexOf(val) > -1) {
        this.$set(this.activeData, name, JSON.parse(val));
      } else {
        this.$set(this.activeData, name, isNumberStr(val) ? +val : val);
      }
    },
    setTimeValue(val, type) {
      const valueFormat = type === "week" ? dateTimeFormat.date : val;
      this.$set(this.activeData.__config__, "defaultValue", null);
      this.$set(this.activeData, "value-format", valueFormat);
      this.$set(this.activeData, "format", val);
    },
    spanChange(val) {
      this.formConf.span = val;
    },
    multipleChange(val) {
      this.$set(this.activeData.__config__, "defaultValue", val ? [] : "");
    },
    dateTypeChange(val) {
      this.setTimeValue(dateTimeFormat[val], val);
    },
    rangeChange(val) {
      this.$set(
        this.activeData.__config__,
        "defaultValue",
        val ? [this.activeData.min, this.activeData.max] : this.activeData.min
      );
    },
    rateTextChange(val) {
      if (val) this.activeData["show-score"] = false;
    },
    rateScoreChange(val) {
      if (val) this.activeData["show-text"] = false;
    },
    colorFormatChange(val) {
      this.activeData.__config__.defaultValue = null;
      this.activeData["show-alpha"] = val.indexOf("a") > -1;
      this.activeData.__config__.renderKey = +new Date(); // 更新renderKey,重新渲染该组件
    },
    openIconsDialog(model) {
      this.iconsVisible = true;
      this.currentIconModel = model;
    },
    setIcon(val) {
      this.activeData[this.currentIconModel] = val;
    },
    tagChange(tagIcon) {
      let target = inputComponents.find(
        (item) => item.__config__.tagIcon === tagIcon
      );
      if (!target)
        target = selectComponents.find(
          (item) => item.__config__.tagIcon === tagIcon
        );
      console.log(target, "targettarget");

      this.$emit("tag-change", target);
    },
    changeRenderKey() {
      if (needRerenderList.includes(this.activeData.__config__.tag)) {
        this.activeData.__config__.renderKey = +new Date();
      }
    },
    handleIdentifyType(val) {
      let url = "/api/file/uploadOrc?type=";
      this.activeData.action = url + val;
    },
    async handleFillinType(val) {
      let res = await getVModel({ type: val });
      this.typeList = res.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.right-board {
  width: 350px;
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 3px;
  height: 100%;
  .field-box {
    position: relative;
    height: calc(100% - 39px);
    box-sizing: border-box;
    overflow: hidden;
  }
  .el-scrollbar {
    height: 100%;
  }
}
.select-item {
  display: flex;
  border: 1px dashed #fff;
  box-sizing: border-box;
  & .close-btn {
    cursor: pointer;
    color: #f56c6c;
  }
  & .el-input + .el-input {
    margin-left: 4px;
  }
}
.select-item + .select-item {
  margin-top: 4px;
}
.select-item.sortable-chosen {
  border: 1px dashed #409eff;
}
.select-line-icon {
  line-height: 32px;
  font-size: 22px;
  padding: 0 4px;
  color: #777;
}
.option-drag {
  cursor: move;
}
.time-range {
  .el-date-editor {
    width: 227px;
  }
  ::v-deep .el-icon-time {
    display: none;
  }
}
.document-link {
  position: absolute;
  display: block;
  width: 26px;
  height: 26px;
  top: 0;
  left: 0;
  cursor: pointer;
  background: #409eff;
  z-index: 1;
  border-radius: 0 0 6px 0;
  text-align: center;
  line-height: 26px;
  color: #fff;
  font-size: 18px;
}
.node-label {
  font-size: 14px;
}
.node-icon {
  color: #bebfc3;
}
::v-deep .el-input.is-disabled .el-input__inner {
  color: #606266;
}
</style>
