import request from '@/utils/request';

// 查询参数列表
export function listConfig(query) {
	return request({
		url: '/system/config/list',
		method: 'get',
		params: query
	});
}

// 查询参数详细
export function getConfig(configId) {
	return request({
		url: '/system/config/' + configId,
		method: 'get'
	});
}

// 根据参数键名查询参数值
export function getConfigKey(configKey) {
	return request({
		url: '/system/config/configKey/' + configKey,
		method: 'get'
	});
}

// 新增参数配置
export function addConfig(data) {
	return request({
		url: '/system/config',
		method: 'post',
		data: data
	});
}

// 修改参数配置
export function updateConfig(data) {
	return request({
		url: '/system/config',
		method: 'put',
		data: data
	});
}

// 删除参数配置
export function delConfig(configId) {
	return request({
		url: '/system/config/' + configId,
		method: 'delete'
	});
}

// 刷新参数缓存
export function refreshCache() {
	return request({
		url: '/system/config/refreshCache',
		method: 'delete'
	});
}

// 刷新参数缓存
export function getTable(vwTable, data) {
	return request({
		url: '/order/searchCommon/list/' + vwTable,
		method: 'post',
		data: data
	});
}
export function saveUserTable(vwTable, data) {
	return request({
		url: '/order/searchCommon/saveUserTable/' + vwTable,
		method: 'post',
		data: data
	});
}

export function saveUserSearch(vwTable, data) {
	return request({
		url: `/order/searchCommon/saveUserSearch/${vwTable}`,
		method: 'post',
		data: data
	});
}
export function saveTool(data) {
	return request({
		url: '/order/bpmPersonTool/saveTool',
		method: 'post',
		data: data
	});
}

export function editTool(data) {
	return request({
		url: '/order/bpmPersonTool',
		method: 'put',
		data: data
	});
}

export function deleteTool(data) {
	return request({
		url: '/order/bpmPersonTool',
		method: 'delete',
		params: data
	});
}
export function saveTemplateFile(data) {
	return request({
		url: '/order/bpmTemplateFileConfig',
		method: 'post',
		data: data
	});
}
export function fixTemplateFile(data) {
	return request({
		url: '/order/bpmTemplateFileConfig',
		method: 'put',
		data: data
	});
}
export function delTemplateFile(data) {
	return request({
		url: '/order/bpmTemplateFileConfig',
		method: 'delete',
		params: data
	});
}


export function getTemplateFile(id) {
	return request({
		url: '/order/bpmTemplateFileConfig/'+id,
		method: 'get',
	});
}


export function previewTemplateFile(data) {
	return request({
		url: '/order/bpmTemplateFileConfig/previewTemplateFile',
		method: 'post',
		responseType: 'arraybuffer',
		data: data
	});
}


export function getTeomplateFilesByProjectCode(params) {
	return request({
		url: '/order/bpmTemplateFileConfig/getTeomplateFilesByProjectCode',
		method: 'get',
		params: params
	});
}



export function getVModel(params) {
	return request({
		url: '/bpm/api/getVModel',
		method: 'get',
		params: params
	});
}


// export function getVModelData(params) {
// 	return request({
// 		url: '/bpm/api/getVModelData',
// 		method: 'post',
// 		data: params
// 	});
// }
export function getVModelData(data) {
	return request({
		url: '/bpm/api/getVModelData',
		method: 'post',
		data: data
	});
}


export function analyzeData(type,data) {
	return request({
		url: `/bpm/api/analyzeData?type=${type}`,
		method: 'post',
		data: data
	});
}

export function saveCrmContact(data) {
	return request({
		url: '/bpm/api/saveCrmContact',
		method: 'post',
		data: data
	});
}

export function getFixedFieldData(params) {
	return request({
		url: '/order/bpmTemplateFileConfig/getFixedFieldData',
		method: 'get',
		params: params
	});
}