var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "outer-box" },
    [
      _c(
        "el-badge",
        {
          staticClass: "dot_item",
          attrs: { max: 99, value: _vm.infoNoticeNum },
        },
        [
          _c("svg-icon", {
            attrs: { "class-name": "search-icon", "icon-class": "email" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.openNotice($event)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "消息通知",
            visible: _vm.showNotice,
            "append-to-body": true,
            direction: "rtl",
            size: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showNotice = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "message-container" },
            [
              _c(
                "el-tabs",
                {
                  attrs: { type: "card" },
                  on: { "tab-click": _vm.getList },
                  model: {
                    value: _vm.queryParams.noticeType,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "noticeType", $$v)
                    },
                    expression: "queryParams.noticeType",
                  },
                },
                _vm._l(
                  _vm.dict.type.bpm_record_task_notice_type,
                  function (item, index) {
                    return _c("el-tab-pane", {
                      key: index,
                      attrs: { label: item.label, name: item.value },
                    })
                  }
                ),
                1
              ),
              _c(
                "div",
                { staticClass: "message-list" },
                [
                  _c(
                    "div",
                    { staticClass: "message-header" },
                    [
                      _c(
                        "div",
                        { staticStyle: { "margin-bottom": "20px" } },
                        [
                          _c(
                            "el-checkbox",
                            {
                              staticStyle: { "margin-right": "20px" },
                              on: { change: _vm.changeAll },
                              model: {
                                value: _vm.selectAll,
                                callback: function ($$v) {
                                  _vm.selectAll = $$v
                                },
                                expression: "selectAll",
                              },
                            },
                            [_vm._v("全选")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: { click: _vm.markAllRead },
                            },
                            [_vm._v("全部已读")]
                          ),
                        ],
                        1
                      ),
                      _c("el-switch", {
                        attrs: {
                          "inactive-value": "0",
                          "active-value": null,
                          "active-text": "查看全部",
                          "inactive-text": "仅看未读",
                        },
                        on: { change: _vm.getList },
                        model: {
                          value: _vm.queryParams.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "status", $$v)
                          },
                          expression: "queryParams.status",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    {
                      staticClass: "message-content",
                      style: { height: _vm.windowHeight + "px" },
                    },
                    _vm._l(_vm.todoMessages, function (message, index) {
                      return _c(
                        "el-card",
                        {
                          key: index,
                          staticClass: "message-item",
                          nativeOn: {
                            click: function ($event) {
                              return _vm.goDetail(message)
                            },
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 1 } },
                                [
                                  _c("el-checkbox", {
                                    staticClass: "checkbox",
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.boxEvent($event)
                                      },
                                    },
                                    model: {
                                      value: message.checked,
                                      callback: function ($$v) {
                                        _vm.$set(message, "checked", $$v)
                                      },
                                      expression: "message.checked",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("el-col", { attrs: { span: 18 } }, [
                                _c(
                                  "div",
                                  { staticClass: "message_tle" },
                                  [
                                    _c("dict-tag", {
                                      attrs: {
                                        options:
                                          _vm.dict.type
                                            .bpm_record_task_notice_msg_type,
                                        value: message.msgType,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "message_detail" }, [
                                  _vm._v(_vm._s(message.noticeTitle)),
                                ]),
                                _c("div", { staticClass: "message_operator" }, [
                                  _vm._v("操作人：" + _vm._s(message.createBy)),
                                ]),
                              ]),
                              _c(
                                "el-col",
                                {
                                  staticClass: "message_right",
                                  attrs: { span: 5 },
                                },
                                [
                                  message.status !== "0"
                                    ? _c(
                                        "el-tag",
                                        {
                                          staticClass: "message_status",
                                          attrs: { type: "success" },
                                        },
                                        [_vm._v("已读")]
                                      )
                                    : _c(
                                        "el-tag",
                                        { staticClass: "message_status" },
                                        [_vm._v("未读")]
                                      ),
                                  _c("div", { staticClass: "message_time" }, [
                                    _vm._v(_vm._s(message.createTime)),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [10, 20, 30, 50],
                      "page-size": _vm.queryParams.size,
                      total: _vm.total,
                      layout: "total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handlecurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            visible: _vm.noticeVisible,
            title: "系统通知",
          },
          on: {
            "update:visible": function ($event) {
              _vm.noticeVisible = $event
            },
            close: function ($event) {
              _vm.noticeVisible = false
            },
          },
        },
        [_c("p", [_vm._v(_vm._s(_vm.noticeTitle))])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }