import { getTable, saveUserSearch, saveUserTable } from '@/api/system/config';
export default {
	computed: {
		filteredHeadFixData() {
		  return this.headFixData.filter(item => this.headData.length === 0 || this.headData.includes(item.headField));
		}
	  },
	data() {
		return {
			listData: [],
			otherData: [],
			searchData: [],
			headData: [],
			headFixData: [],
			isPadding: false,
			orders:[]
		};
	},
	methods: {
		async	getList1() {
			let response = await getTable(this.queryParams.vw_table, this.queryParams).then()
			if(response) {
				this.otherData = response.data.otherData;
				this.searchData = response.data.searchData;
			}
			return response.data
		},
		// 获取列表数据
		async getList() {
			window.addEventListener('resize', this.handleResize);
		
			if (this.isPadding) {
				return false; // 如果正在加载，直接返回
			}
		
			this.isPadding = true;
			this.loading = true;
		
			try {
				const response = await getTable(this.queryParams.vw_table, this.queryParams);
		
				// 处理响应数据
				this.listData = response.data.tableData.records;
				this.total = response.data.tableData.total;
				this.otherData = response.data.otherData;
				this.searchData = response.data.searchData;
				this.headData = response.data.headData.filter((colOption) => colOption.showField).map((colOption) => colOption.headField);
				this.headFixData = response.data.headData.length > 0 ? response.data.headData : response.data.headFixData;
		
				// 使用 $nextTick 确保 DOM 更新完成后再执行布局方法
				await this.$nextTick();
				
				if (this.$refs.table && typeof this.$refs.table.doLayout === 'function') {
					this.$refs.table.doLayout();
				} else {
					console.error("this.$refs.table未定义或者缺少doLayout方法");
				}
			} catch (error) {
				console.error(error); // 处理错误
			} finally {
				this.loading = false; // 无论成功还是失败，都需要更新状态
				this.isPadding = false; // 重置标志
			}
		},
		
		handleSortChange({ column, prop, order }) {
			console.log(column, prop, order,'column, prop, order');
			if (!order) {
			  this.orders = [];
			} else {
			  if (order == "descending") {
				let obj1 = { asc: false, column:this.searchFieldForProp(prop)? this.searchFieldForProp(prop) +'.'+ prop: prop} ;
				this.orders[0] = obj1;
			  } else {
				let obj2 = { asc: true,  column:this.searchFieldForProp(prop)? this.searchFieldForProp(prop) +'.'+ prop: prop};
				this.orders[0] = obj2;
			  }
			  this.queryParams.orders = this.orders;
			}
			
			this.getList();
		  },
		  searchFieldForProp(prop) {
			const foundItem = this.headFixData.find(item => item.headField == prop);
			console.log(foundItem,'foundItem');
			return foundItem ? foundItem.searchField : null;
		  },
		handleResize(){
			let that = this
			that.$nextTick(()=>{
				if (that.$refs.table && typeof that.$refs.table.doLayout === 'function') {
					that.$refs.table.doLayout();
				} else {
					console.error("this.$refs.table未定义或者缺少doLayout方法");
				}
			})
		},
	async handleSearch(query, searchItems) {
			if (query) {
				Object.assign(this.queryParams, query);
			}
			this.queryParams.current = 1;
			if (searchItems.length > 0) {
				this.getList();
				this.saveSearch(searchItems);
			}else{
				await this.getList();
				this.saveSearch(searchItems);
			}
	
		},
		// 保存搜索项
		async saveSearch(searchItems) {
			let res = await saveUserSearch(this.queryParams.vw_table, searchItems);
		},
		// 保存搜索表头
		async saveSearchHead(headData) {
			let res = await saveUserTable(this.queryParams.vw_table, headData);
			if (res.code == 200) {
				this.$modal.msgSuccess('保存表头成功');
				this.$set(this, 'headFixData', []);
				this.$set(this, 'headData', []);
				this.getList();
			}
		}
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.handleResize);
	  },
};
