import { deepClone } from '@/utils/index'

const componentChild = {}
/**
 * 将./slots中的文件挂载到对象componentChild上
 * 文件名为key，对应JSON配置中的__config__.tag
 * 文件内容为value，解析JSON配置中的__slot__
 */
const slotsFiles = require.context('./slots', false, /\.js$/)
const keys = slotsFiles.keys() || []

let uploadedFiles = 0;
let confCloneCopy=''
let totalFiles = 0;
let uploadList =[]
keys.forEach(key => {
  const tag = key.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = slotsFiles(key).default
  componentChild[tag] = value
})

function vModel(dataObject, defaultValue, config,confClone) {
  // 获取上传表单元素组件上传的文件
  if (config.tag === "el-upload") {
    // 上传表单元素组件的成功和移除事件
    dataObject.attrs["on-success"] = (response, file, fileList) => {
      let confCloneAllId= confClone.moduleIds+confClone.__vModel__
      if (totalFiles) {
        uploadedFiles=fileList.length-1
        console.log('uploadedFiles2-1',uploadedFiles);
      }else{
        uploadedFiles=0
        console.log('uploadedFiles2-2',uploadedFiles);
      }
      confCloneCopy=confCloneAllId
      console.log('confClone',confClone);
      if(response.code == 200) {
        uploadedFiles++;
        if (uploadedFiles === totalFiles) {
          console.log('上传所有文件结束');
          // 所有文件上传完成，调用父组件的 upload 方法
          this.$emit("upload", response, file, fileList);
        }
      } else {
        this.$modal.msgError(response.msg);
        console.log('fileList',fileList);
        uploadList=fileList.filter(item => {
          if (!item.response) {
            return item
          }
          if(item.response&&item.response.code == 200){
            return item
          }
          return false;
        })
        // fileList = list
        // dataObject.props["file-list"] = list
        this.$set(dataObject.props, "file-list", uploadList);
        this.$forceUpdate();
      }
     
    };  
    dataObject.attrs["on-remove"] = (file, fileList) => {
      this.$emit("deleteUpload", file, fileList);
    };

    dataObject.attrs["on-preview"] = (file, fileList) => {
      this.$emit("previewUpload", file, fileList);
    };
    dataObject.attrs["on-change"] = (file, fileList) => {
      totalFiles=fileList.length
      // console.log('选中了',totalFiles);
      // console.log('选中了uploadedFiles',uploadedFiles);
    };
    dataObject.attrs["before-upload"] = (file, fileList) => {
      // return false
    };
    // 获取上传表单元素的默认值
    try {
      dataObject.props["file-list"] = JSON.parse(defaultValue);
      if (config.limit) {
        dataObject.props["limit"] = config.limit
      }
      // dataObject.props["limit"] = 1
    } catch (err) {
      // console.log('上传列表',uploadList);
      dataObject.props["file-list"] =uploadList
      totalFiles=uploadList.length
      uploadedFiles=uploadList.length
      // console.log('上传失败error');
    }

    return;
  }

  // 获取普通表单元素的值
  dataObject.props.value = defaultValue;
  dataObject.on.input = (val) => {
    this.$emit("input", val);
  };
  dataObject.on.blur = val => {
    this.$emit('blur', val)
  };
  // 获取按钮表单元素的值
  if (config.tag === "el-button") {
    dataObject.on.click = val => {
      this.$emit('click', val)
    };
  }
}

function mountSlotFiles(h, confClone, children) {
  const childObjs = componentChild[confClone.__config__.tag]
  if (childObjs) {
    Object.keys(childObjs).forEach(key => {
      const childFunc = childObjs[key]
      if (confClone.__slot__ && confClone.__slot__[key]) {
        children.push(childFunc(h, confClone, key))
      }
    })
  }
}

function emitEvents(confClone) {
  ['on', 'nativeOn'].forEach(attr => {
    const eventKeyList = Object.keys(confClone[attr] || {})
    eventKeyList.forEach(key => {
      const val = confClone[attr][key]
      if (typeof val === 'string') {
        confClone[attr][key] = event => this.$emit(val, event)
      }
    })
  })
}

function buildDataObject(confClone, dataObject) {
  Object.keys(confClone).forEach(key => {
    const val = confClone[key]
    if (key === '__vModel__') {
      // const regex = /^-?\d+$/; // 匹配只包含整数的字符串
      // if((confClone.__config__.tag === "el-radio-group" || confClone.__config__.tag === "el-select") && regex.test(confClone.__config__.defaultValue)) {
      //   this.$set(confClone.__config__,'defaultValue', parseInt(confClone.__config__.defaultValue))
      // }
      vModel.call(this, dataObject, confClone.__config__.defaultValue, confClone.__config__,confClone)
    } else if (dataObject[key] !== undefined) {
      if (dataObject[key] === null
        || dataObject[key] instanceof RegExp
        || ['boolean', 'string', 'number', 'function'].includes(typeof dataObject[key])) {
        dataObject[key] = val
      } else if (Array.isArray(dataObject[key])) {
        dataObject[key] = [...dataObject[key], ...val]
      } else {
        dataObject[key] = { ...dataObject[key], ...val }
      }
    } else {
      dataObject.attrs[key] = val
    }
  })

  // 清理属性
  clearAttrs(dataObject)
}

function clearAttrs(dataObject) {
  delete dataObject.attrs.__config__
  delete dataObject.attrs.__slot__
  delete dataObject.attrs.__methods__
}

function makeDataObject() {
  // 深入数据对象：
  // https://cn.vuejs.org/v2/guide/render-function.html#%E6%B7%B1%E5%85%A5%E6%95%B0%E6%8D%AE%E5%AF%B9%E8%B1%A1
  return {
    class: {},
    attrs: {},
    props: {},
    domProps: {},
    nativeOn: {},
    on: {},
    style: {},
    directives: [],
    scopedSlots: {},
    slot: null,
    key: null,
    ref: null,
    refInFor: true
  }
}

export default {
  props: {
    conf: {
      type: Object,
      required: true
    }
  },
  render(h) {
    const dataObject = makeDataObject()
    const confClone = deepClone(this.conf)
    const children = this.$slots.default || []

    // 如果slots文件夹存在与当前tag同名的文件，则执行文件中的代码
    mountSlotFiles.call(this, h, confClone, children)

    // 将字符串类型的事件，发送为消息
    emitEvents.call(this, confClone)

    // 将json表单配置转化为vue render可以识别的 “数据对象（dataObject）”
    buildDataObject.call(this, confClone, dataObject)

    return h(this.conf.__config__.tag, dataObject, children)
  }
}
